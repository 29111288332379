import { useEffect, useState } from 'react'
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react'

import { baseUrl, IS_DEV } from '../env'
import { positionHandler, positionErrorHandler } from '../util/location'

import Admin from './Admin'
import Checklist from './Checklist'
import History from './History'
import Login from './Login'
import Orders from './Orders'
import PunishmentStats from './PunishmentStats'
import ReminderForm from './ReminderForm'
import RewardStats from './RewardStats'
import Settings from './Settings'
import Supertasks from './Supertasks'
import TaskCardStack from './TaskCardStack'

const defaultLocalSettings = {
  showTasksTab: true,
  showSupertasksTab: true,
  showChecklistsTab: true,
  showRemindersTab: true,
  showHistoryTab: true,
  showRewardsTab: true,
  showPunishmentsTab: true,
  showOrdersTab: true,
  showAdminTab: false
} as Record<string, boolean>

const getTabs = (
  adminView: boolean,
  serverSettings: Record<string, any>,
  localSettings: Record<string, boolean>,
  props: Record<string, Record<string, any>>
) => {
  const showCensorable = adminView || !(serverSettings?.censored)

  const tabs = [
    ['Tasks', TaskCardStack, {}, localSettings.showTasksTab],
    ['Supertasks', Supertasks, {}, localSettings.showSupertasksTab],
    ['Checklists', Checklist, {}, localSettings.showChecklistsTab],
    ['Reminders', ReminderForm, {}, localSettings.showRemindersTab],
    ['History', History, {}, localSettings.showHistoryTab],
    ['Rewards', RewardStats, {}, localSettings.showRewardsTab],
    ['Punishments', PunishmentStats, {}, showCensorable && localSettings.showPunishmentsTab],
    ['Orders', Orders, {}, showCensorable && localSettings.showOrdersTab],
    ['Settings', Settings, props.Settings, true],
    ['Admin', Admin, {}, localSettings.showAdminTab || adminView]
  ] as const

  return tabs.filter(t => t[3])
}

function App() {
  const [serverSettings, setServerSettings] = useState({} as Record<string, any>)
  const [localSettings, setLocalSettings] = useState(defaultLocalSettings)
  const [tabIndex, setTabIndex] = useState(Number(window.localStorage.getItem('tabIndex')) || 0)

  useEffect(() => {
    fetch(`${baseUrl}/settings`)
    .then(response => response.json())
    .then(data => {
      setServerSettings(data)
      window.localStorage.setItem('settings', JSON.stringify(data))
    })
  }, [])

  const cookies = document.cookie.split(';').map(c => c.trim())
  const hasAuthToken = cookies.some(c => c.startsWith('token='))
  if (!hasAuthToken && !IS_DEV) {
    return <div className="App">
      <Login />
    </div>
  }

  const isAdmin = cookies.some(c => c.startsWith('admin_token='))
  const adminView = localStorage.getItem('adminView') === 'true'

  const location = navigator.geolocation
  if (location && ((!IS_DEV && !isAdmin) || (IS_DEV && !adminView))) {
    location.getCurrentPosition(positionHandler, positionErrorHandler)
  }

  const tabs = getTabs(adminView, serverSettings, localSettings, {
    Settings: {
      settings: localSettings,
      setSettings: setLocalSettings,
      tabIndex,
      setTabIndex
    }
  })

  return (
      <div className="App">
        <Tabs
          isLazy={true}
          index={tabIndex}
          onChange={(index) => {
            setTabIndex(index)
            window.localStorage.setItem('tabIndex', index.toString())
          }}
        >
          <Box overflow="auto">
            <TabList w="max-content">
              {tabs.map(([name]) => (
                <Tab key={name}>{name}</Tab>
              ))}
            </TabList>
          </Box>
          <TabPanels>
            {tabs.map(([name, Component, props]) => (
              <TabPanel key={name}>
                { /* @ts-expect-error */ }
                {<Component {...props} />}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </div>
  )
}

export default App
